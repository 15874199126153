.MuiButton-root {
/*   */
}

.myFixedSlide {
    width: 200px !important; /* Forcer la largeur des slides */
    flex-shrink: 0;         /* Empêche les slides de rétrécir */
}


.radius-1 {
    border-radius: 5px;
}

.MuiDivider-root {
    height: 1px !important;
}

.hidden {
    display: none !important;
}

.MuiCardHeader-action {
    flex: 0 0 auto;
    align-self: flex-end !important;
    margin-top: -8px;
    margin-right: -8px;
}

.flicking-viewport {
    max-height: 150px !important;
}

.flicking-panel {
    margin: 0; /* Aucune marge */
    padding: 0; /* Aucun padding */
    width: auto; /* Laisse la largeur des éléments s'adapter */
    text-align: left; /* Forcer l'alignement à gauche */
}


/* src/components/Sidebar.css */
.auth-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.auth-button {
    background-color: #e91e63;
    color: #fff;
    border: none;
    padding: 5px 5px;
    font-size: 1rem;
    margin: 5px 5px; /* Ajuste cette valeur pour qu'elle corresponde à l'icône */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: calc(100% - 28px); /* 100% moins les marges gauche et droite */
    text-align: center;
}

.auth-button:hover {
    background-color: #c2185b;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .auth-button {
        font-size: 0.9rem;
        padding: 8px 16px;
        margin: 5px 20px; /* Conserve les marges latérales de 20px */
        width: calc(100% - 28px); /* Conserve la largeur en tenant compte des marges */
    }
}

@media (max-width: 480px) {
    .sidebar {
        width: 100%;
        height: auto;
    }

    .auth-buttons {
        flex-direction: column; /* Garde les boutons en colonne pour conserver les marges */
        align-items: center;
    }

    .auth-button {
        font-size: 0.8rem;
        padding: 8px 16px;
        margin: 5px 20px; /* Conserve les marges latérales de 20px */
        width: calc(100% - 40px); /* Conserve la largeur en tenant compte des marges */
    }
}

.radius-top-1 {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.MuiMenu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiBox-root {
    box-shadow: unset !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-help {
    cursor: help;
}

.cursor-wait {
    cursor: wait;
}

.cursor-crosshair {
    cursor: crosshair;
}

.cursor-text {
    cursor: text;
}

.Mui-selected {
    color: #ED1C24 !important;
}

.MuiButtonBase-root {
    border-radius: 5px !important;
}

.MuiListItem-button {
    height: 60px !important;
    padding: 0px 12px 0px 0px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.PrivateDatePickerToolbar-penIcon {
    visibility: hidden !important;
}

.MuiTabs-indicator {
    background-color: #ED1C24 !important;
}

.MuiTabs-flexContainer {
    justify-content: space-between !important;
}
.MuiSvgIcon-root {
    color: #ED1C24 !important;
}

.MuiPagination-ul {
    justify-content: center;
}
.MuiFormControl-root {
    width: 100% !important;
}

.card-flex-row {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem
}

.card-flex-row-no-bg {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem
}

.MuiButton-textPrimary {
    color: #fff !important;
}

.vh-75 {
    min-height: 92vh !important;
}

.vh-25 {
    min-height: 25vh !important;
}

.vh-50 {
    min-height: 80vh !important;
}

.stepper__row {
    justify-content: space-between;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    transition: margin 0.4s;
}
.flicking-viewport {
    height: 400px !important;
}

.stepper__row:last-child {
    margin-bottom: 0;
}

.stepper__row:last-child .button__rectangle {
    display: none;
}

.stepper--vertical {
    display: flex;
    position: relative;
}

.stepper--vertical__circle {
    background: black;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    position: relative;
}

.stepper--vertical__circle:after {
    content: url('http://res.cloudinary.com/dhwx8elvg/image/upload/v1499454191/stepper-vertical-line_gu7yjs.svg');
    position: absolute;
    top: 50px;
    margin-top: 10px;
    transform-origin: 100% -35%;
    transition: transform 2s;
}

.stepper--vertical__circle__text {
    align-self: center;
    font-size: 15px;
    margin-top: 25px;
    font-weight: 450;
    color: #51cbce;
}

.width-full {
    width: 100% !important;
}

.height-full {
    height: 100% !important;
}

.stepper--vertical__details {
    margin-left: 10px;
    margin-top: 10px;
    align-self: center;
    width: 400px;
}

@media (max-width: 500px) {
    .stepper--vertical__details {
        width: 300px; /* Largeur pour les écrans de moins de 500px */
    }
}

.stepper--vertical__details .paragraph {
    margin-top: 12px;
    transition: all 0.2s;
}

.stepper--vertical__details .button__rectangle {
    margin-top: 10px;
}
.stepper__row:last-child .stepper--vertical__circle:after {
    content: "";
    display: none;
}
.stepper__row--disabled {
    margin-bottom: 25px;
}
.stepper__row--disabled .button__rectangle {
    display: none;
}

.stepper__row--disabled .stepper--vertical__circle {
    background: #343a40;
    color: cornsilk !important;
}
.stepper__row--disabled .stepper--vertical__circle__text {
    color: #747371 !important;
}
.stepper__row--disabled .stepper--vertical__circle:after {
    transform: scaleY(0.60);
}
.stepper__row--disabled .stepper--vertical__details .paragraph {
    color: rgba(62, 78, 104, 0.8);
}
.stepper__row--disabled .stepper--vertical__details .heading__three {
    color: rgba(62, 78, 104, 0.7);
}

.stepper__row:hover {
}

.button__circle {
    border: 2px solid #51cbce;
    border-radius: 96px;
    height: 44px;
    width: 44px;
    position: relative;
    transition: all 0.05s;
    background: transparent !important;
}

.button__circle__middle {
    position: absolute;
    transform: translate(-50%);
    top: 32%;
    background: transparent !important;
    left: 50%;
}

.button__group {
    display: flex;
}

.button__group .button__rectangle__button,
.button__group .button__circle {
    margin-left: 32px;
}

.button__group .button__rectangle__button:first-child,
.button__group .button__circle:first-child {
    margin-left: 0px;
}

.button__group--margin-top {
    margin-top: 32px;
}
.button__rectangle__button__icon svg {
    fill: #FFF;
}
.button__circle:hover {
    transform: scale(1.025) translateY(-1px);
    background: #388CF3;
}
.button__circle:hover .button__arrow {
    fill: #FFF;
}
.button__circle:active {
    transform: scale(.975) translateY(1px);
}
.button__arrow {
    fill: #51cbce;
}

.button__rectangle__button{
    border: none;
    padding: 10px 30px;
    background: #51cbce;
    outline: 0;
    cursor: pointer;
    border-radius: 3px;
    color: #FFF;
    font-size: 14px;
    transition: all 0.05s;
    font-weight: 600;
    margin: 0 auto;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    text-rendering: optimizeLegibility !important;
    font-smooth: always !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.button__rectangle__button:first-letter {
    text-transform: uppercase;
}


/* Icon Handlers */
.button__rectangle__button__icon {
    padding-right: 8px;
}

.button__rectangle__button--default--secondary--icon .button__rectangle__button__icon svg {
    fill: #51cbce;
}
.button__rectangle__button--default--secondary--icon:hover .button__rectangle__button__icon svg {
    fill: #2A476D;
}

.button__rectangle__button--neutral--secondary--icon .button__rectangle__button__icon svg {
    fill: #647A89;
}
.button__rectangle__button--neutral--secondary--icon:hover .button__rectangle__button__icon svg {
    fill: #3E4E68;
}

.button__rectangle__button--negative--secondary--icon .button__rectangle__button__icon svg {
    fill: #FF4876;
}
.button__rectangle__button--negative--secondary--icon:hover .button__rectangle__button__icon svg {
    fill: #6D2A43;
}

.button__rectangle__button--default--secondary,
.button__rectangle__button--default--secondary--icon {
    border: none;
    padding: 10px 30px;
    background: #FFF;
    outline: 0;
    cursor: pointer;
    border-radius: 3px;
    color: #388CF3;
    font-size: 14px;
    transition: all 0.05s;
    border: 2px solid #51cbce;
    font-weight: 600;
    background: #FFF;
}

.button__rectangle__button--neutral {
    background: #647A89;
}
.button__rectangle__button--neutral--secondary,
.button__rectangle__button--neutral--secondary--icon{
    border: 2px solid #647A89;
    color: #647A89;
    background: #FFF;
}

.button__rectangle__button--negative {
    background: #FF4876;
}
.button__rectangle__button--negative--secondary,
.button__rectangle__button--negative--secondary--icon {
    border: 2px solid #FF4876;
    color: #FF4876;
    background: #FFF;
}
.button__rectangle__button--default--negative {
    border-color: #FF4876;
    color: #FF4876;
}
.button__rectangle__button:hover {
    transform: scale(1.05) translateY(-1px);
    background: #388CF3;
}
.button__rectangle__button--default--secondary:hover,
.button__rectangle__button--default--secondary--icon:hover {
    transform: scale(1.05) translateY(-1px);
    border-color: #388CF3;
    color: #388CF3;
    background: #FFF;
}
.button__rectangle__button:active {
    transform: scale(.995) translateY(1px);
}
.button__rectangle__button--neutral:hover {
    background: #3E4E68;
}
.button__rectangle__button--neutral--secondary:hover,
.button__rectangle__button--neutral--secondary--icon:hover {
    border-color: #3E4E68;
    color: #3E4E68;
    background: #FFF;
}
.button__rectangle__button--negative:hover {
    background: #6D2A43;
}
.button__rectangle__button--negative--secondary:hover,
.button__rectangle__button--negative--secondary--icon:hover {
    border-color: #6D2A43;
    color: #6D2A43;
    background: #FFF;
}
.button__rectangle__button--default__icon {
    margin-right: 10px;
}



.t-circle {
    width: 20px;
    height: 20px;
    background-color: #b3a356;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: 0 0 1px rgba(0,0,0,.3)
}

.line {
    content: '';
    display: block;
    height: 200px;
    width: 1px;
    background-color: #b3a356;
    margin: 0 auto;
    transform-origin: center;
    animation: animate 3s linear infinite;
}

.banner {
    width: 270px;
    height: 90px;
    margin: 0 auto;
    border-radius: 5px;
    background-color: #b3a356;
    taransform-origine: top;
    animation: animate 2.9s linear infinite;
}


@keyframes animate {
    0% {
        transform: rotate(-2deg);
    }



    50% {

        transform: rotate(2deg);

    }

    100% {

        transform: rotate(-2deg);

    }
}


.invoice-card {
    padding: 10px 2em;
    width: 100%;
}

.invoice-card > div {
    margin: 5px 0;
}

.invoice-title {
    flex: 3;
}

.invoice-title #date {
    display: block;
    /*margin: 8px 0;*/
    font-size: 12px;
}

.invoice-title #main-title {
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
}

.invoice-title #main-title h4 {
    letter-spacing: 2.5px;
}

.invoice-title span {
    color: rgba(0, 0, 0, 0.4);
}

.invoice-details {
    flex: 1;
    border-top: 0.5px dashed grey;
    display: flex;
    align-items: center;
}

.invoice-table {
    width: 100%;
    border-collapse: collapse;
}

.invoice-table thead tr td {
    font-size: 12px;
    letter-spacing: 1px;
    color: grey;
    padding: 8px 0;
}

.invoice-table thead tr td:nth-last-child(1),
.row-data td:nth-last-child(1),
.calc-row td:nth-last-child(1) {
    text-align: right;
}

.invoice-table tbody tr td {
    padding: 8px 0;
    letter-spacing: 0;
}

.invoice-table .row-data #unit {
    text-align: center;
}

.invoice-table .row-data span {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6);
}

.invoice-footer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.invoice-footer #later {
    margin-right: 5px;
}


@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond:400,500,500i,600,600i,700,700i |Cormorant+SC:400,500,600,700');

/* ====================================
General Styles
==================================== */

.page-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right bottom, #2a1f30 50%, #383d44 50%);
}

/* ====================================
Home Slider
==================================== */

#home-slider {
    height: 80vh;
    width: 90vw;
    position: relative;

}
.swiper-container {
    height: 100%;
    width: 100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.swiper-image-one {
    background-image: url(https://source.unsplash.com/GXOGGENgHnQ/1600x900);
}

.swiper-image-two {
    background-image: url(https://source.unsplash.com/Jy6luiLBsrk/1600x900);
}

.swiper-image-three {
    background-image: url(https://source.unsplash.com/sFLVTqNzG2I/1600x900);
}

.swiper-image-four {
    background-image: url(https://source.unsplash.com/pj1jt4vBGZY/1600x900);
}

.swiper-image-five {
    background-image: url(https://source.unsplash.com/Jy6luiLBsrk/1600x900);
}

.swiper-image-six {
    background-image: url(https://source.unsplash.com/bxCgan0J1Sg/1600x900);
}

.swiper-image {
    width: 50%;
    height: 100%;
}
.swiper-image-inner {
    color: cornsilk !important;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 0%, 0.2);
    background-blend-mode: overlay;
}
.swiper-image-inner.swiper-image-left {
    padding: 4rem;
}
.swiper-image-left {
    filter: sepia(100%);
    transition: all 1s linear;
    transition-delay: 1s;
}
.swiper-slide.swiper-slide-active .swiper-image-left {
    filter: sepia(0%);
}
.swiper-image-inner.swiper-image-right {
    filter: hue-rotate(-60deg);
    transition: all 1s linear;
    transition-delay: 1s;
    background-color: hsla(0, 0%, 0%, 0.5);
    background-blend-mode: multiply;
}

.swiper-slide.swiper-slide-active .swiper-image-right {
    filter: hue-rotate(90deg);
}

.swiper-slide.swiper-slide-active h1,
.swiper-slide.swiper-slide-active p.paragraph,
.swiper-slide.swiper-slide-active h1 span.emphasis,
.swiper-slide.swiper-slide-active h1 + p {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

/* Swiper css file. Only thing changed down here is the pagination styling */
/**
 * Swiper 3.4.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: December 13, 2016
 */
.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    /* Fix of Webkit flickering */
    z-index: 1;
}
.swiper-container-no-flexbox .swiper-slide {
    float: left;
}
.swiper-container-vertical > .swiper-wrapper {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.swiper-container-android .swiper-slide, .swiper-wrapper {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto;
}
.swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex;

}
/* Auto Height */
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
    height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-transition-property: -webkit-transform, height;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform, height;
}
/* a11y */
.swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
}
/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}
.swiper-wp8-vertical {
    -ms-touch-action: pan-x;
    touch-action: pan-x;
}
/* Arrows */
.swiper-button-prev, .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    -moz-background-size: 27px 44px;
    -webkit-background-size: 27px 44px;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    left: 10px;
    right: auto;
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    right: 10px;
    left: auto;
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
/* Pagination Styles */
.swiper-pagination {
    position: absolute;
    text-align: center;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px;
    left: 0;
    width: 100%;
}
/* Bullets */
.swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    display: inline-block;
    background: #fff;
    opacity: 0.4;
}
button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}
.swiper-pagination-white .swiper-pagination-bullet {
    background: #fff;
}
.swiper-pagination-bullet-active {
    opacity: 1;
    background: #fff;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
    background: #fff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
    background: #000;
}
.swiper-container-vertical > .swiper-pagination-bullets {
    right: 15px;
    bottom: 15px;
}
.swiper-container-vertical
> .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 5px 0;
    display: block;
}
.swiper-container-horizontal
> .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
}
/* Progress */
.swiper-pagination-progress {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
}
.swiper-pagination-progress .swiper-pagination-progressbar {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
}
.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
    -webkit-transform-origin: right top;
    -moz-transform-origin: right top;
    -ms-transform-origin: right top;
    -o-transform-origin: right top;
    transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progress {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
}
.swiper-container-vertical > .swiper-pagination-progress {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
}
.swiper-pagination-progress.swiper-pagination-white {
    background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
    background: #fff;
}
.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
    background: #000;
}
/* 3D Container */
.swiper-container-3d {
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    -o-perspective: 1200px;
    perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgba(0, 0, 0, 0.5)),
            to(rgba(0, 0, 0, 0))
    );
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(
            right,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(
            right,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(
            right,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(
            to left,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(
            linear,
            right top,
            left top,
            from(rgba(0, 0, 0, 0.5)),
            to(rgba(0, 0, 0, 0))
    );
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(
            left,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(
            left,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(
            left,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(0, 0, 0, 0.5)),
            to(rgba(0, 0, 0, 0))
    );
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(
            bottom,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            from(rgba(0, 0, 0, 0.5)),
            to(rgba(0, 0, 0, 0))
    );
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(
            top,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0)
    );
    /* Firefox 16+, IE10, Opera 12.50+ */
}
/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
    /* Windows 8 IE 10 fix */
    -ms-perspective: 1200px;
}
/* Cube + Flip */
.swiper-container-cube, .swiper-container-flip {
    overflow: visible;
}
.swiper-container-cube .swiper-slide, .swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
}
.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}
/* Cube */
.swiper-container-cube .swiper-slide {
    visibility: hidden;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible;
}
.swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: 0;
}
/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
    pointer-events: none;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}
.swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
}
.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
/* Scrollbar */
.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%;
}
.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
}
.swiper-scrollbar-cursor-drag {
    cursor: move;
}
/* Preloader */
.swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    -webkit-transform-origin: 50%;
    -moz-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes swiper-preloader-spin {
    100% {
        transform: rotate(360deg);
    }
}
